import React, { Component } from 'react';
import AccordionList from '../components/AccordionList/AccordionList.js';
import "../styles/Archive.css"
import { Link } from "gatsby";
import Layout from '../components/Layout.js';

function Page({item, chapterPage}) {
  const href = "/comic/" + chapterPage;
  return (
      <div className="pageWrapper">
        <div className="page-thumb" style={{'backgroundImage': `url(${item.filePath.replace("/pages/", "/thumbs/")})`}}/>
        <Link to={href}>{item.name + (item.matched ? "*" : "")}</Link>
      </div>
  )
}

function Chapter({item}) {
  const pagesList = Object.assign([], item.pages);
  if (item.volCover) {
    item.volCover.chapterPage = `Cover_${item.volCover.pageId}`
    pagesList.unshift(item.volCover);
  }

  return (
    <div className="chapterItem">
      {pagesList.map((listItem, index) => (<Page key={listItem.name} item={listItem} chapterPage={listItem.chapterPage ? listItem.chapterPage : item.id + "_" + listItem.pageId}/>))}
    </div>
  )
}

class Archive extends Component {
  constructor(props) {
    super(props);
    this.state = { chapters: null, status: null };
  }

  fetchChapters() {
    this.setState({ status: 'LOADING' });
    let searchParams = window.location.search;

    return window.fetch('/api/chapters' + searchParams)
        .then(response => response.json())
        .then((result) => {
          this.setState({ chapters: result, status: 'LOADED' });
        }).catch(e => {
          console.error( e);
          this.setState({ status: 'ERROR' });
        });
  }

  componentDidMount() {
    this.fetchChapters();
  }

  render() {
    const { chapters, status } = this.state;

    return (
        <Layout>
          <div className="content">
            {status === 'ERROR' ? (<div className="error-alert">Oops, a request to the server failed. Try reloading.</div>) : null}
            {chapters ? <AccordionList list={chapters} shouldExpand={(folder, folderIx) => folderIx === 0} SlotComponent={Chapter}/> : null}
          </div>
        </Layout>
    );
  }
}

export default Archive;
